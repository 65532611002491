* {
	font-family: Microsoft JhengHei;
}

@font-face {
    font-family: AdventSans;
    src: url("../../fonts/AdventSans-Logo.otf") format("opentype");
}

.committeeIntroArea .breadcrumb, .committeeNewsArea .breadcrumb, .committeeResourcesArea .breadcrumb {
    color: #418593;
    font-weight: bold;
    margin-bottom: 20px;
}

.committeeIntroArea .breadcrumb a, .committeeNewsArea .breadcrumb a, .committeeResourcesArea .breadcrumb a {
    color: #418593;
    font-weight: bold;
}

.committeeIntroArea, .committeeNewsArea,  .committeeResourcesArea {
	display: none;
}

.committeeIntroArea.active, .committeeNewsArea.active,  .committeeResourcesArea.active {
	display: block;
	margin-top: 20px;
	margin-right: 20px;
}

.committeesContainer .bodyContent .wrapper {
	width: calc((100vw/7) * 5);
	margin: auto;
	position: relative;
	height: 100%;
}

.committeesContainer .banner .navigationBar {
	position: absolute;
	bottom: 15px;
	display: flex;
	/*height: 20px;*/
}

.committeesContainer .banner img {
	height: 25px;
	width: 25px;
	position: relative;
	top: -4px;
}

.committeesContainer .banner h1 {
	color: white;
    position: relative;
    margin: 0;
    margin: 0 15px;
    font-size: 25px;
    /*line-height: 50px;*/
}

.committeesContainer .bodyContent .subCatBanner .wrapper {
	width: calc(100% - 40px);
	margin-left: 20px;
	margin-right: 20px;
	padding: 0;
}

.committeesContainer .wrapper .subCatMenu li {
	text-align: center;
	/*width: 80px;*/
}

.committeesContainer .navigationIcon {
	width: 30px;
}

.committeesContainer .navigationIcon:hover {
	cursor: pointer;
}

.committeeNewsArea .newsListing {
	/*width: 70%;*/
}

.committeeNewsArea .newsListing .newsGroup {
	display: flex;
	/*width: 60%;*/
	margin-bottom: 30px;
	

	/*border: solid 1px red;*/
}

.committeeNewsArea .newsListing .newsGroup div.thumbnailArea { 
	/*width: 100%;*/
	/*overflow: hidden;*/
}

.committeeNewsArea .newsListing .newsGroup img {
	width: 100%;
	max-width: 200px;
	height: 100%;
	max-height: 275px;
    object-fit: cover;
    object-position: top left;
    cursor: pointer;
}

.committeeNewsArea .newsListing .newsGroup div.descArea {
	min-width: 50%;
	/*border: solid 1px green;*/
}

.committeeNewsArea .newsListing .newsGroup p {
	margin: 0;
	color: #666262;
	font-family: AdventSans;
	font-size: 12px;
}

.committeeNewsArea .newsListing .newsGroup h4 {
	font-weight: bold;
	font-size: 18px;
    cursor: pointer;
}

.committeeNewsArea .newsListing .newsGroup h5 {
	font-weight: lighter;
	font-size: 14px;
}

.committeeNewsArea .newsListing .newsGroup h4 {
	margin: 0;
	padding: 0 20px;
}

.committeeNewsArea .newsListing .newsGroup h5 {
	margin: 0;
	padding: 5px 20px;
}

.committeeNewsArea .newsListing .newsGroup .readMore {
	font-size: 16px;
	text-align: center;
	border: solid 2px #2f557f;
	color: #2f557f;
	font-weight: bold;
	border-radius: 5px;
	width: 100px;
	margin-left: 20px;
	margin-top: 5px;
}

.committeeResourcesArea .resourcesHeading {
	border-bottom: solid 1px #2f557f;
	color: #2f557f;
	font-weight: bold;
}

.committeeResourcesArea .resourcesRows {
	display: flex;
	height: 35px;
	color: #2f557f;
	justify-content: space-between;
	/*border: solid 1px red;*/
}


.committeeResourcesArea .resourcesRows .optionRows {
	display: flex;
	flex-direction: row;
}

.committeeResourcesArea .resourcesRows > p {
	cursor: pointer;
}

.committeeResourcesArea .resourcesRows .optionRows p {
	margin-left: 10px;
	cursor: pointer;
}

.committeeResourcesArea .resourcesRows p {
	margin: 0;
	line-height: 35px;
	word-wrap: break-word;
	word-break: break-all;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.committeeResourcesArea .resourceGridBox {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	justify-content: flex-start;
	margin-top: 20px;
	flex-wrap: wrap;
}

.committeeResourcesArea .resourceGridBox .resourceGridItem {
	width: 12%;
	display: block;
	margin: 20px;
	cursor: pointer;
}

.committeeResourcesArea .resourceGridBox .resourceGridItem img, .committeeResourcesArea .MuiSvgIcon-root {
	width: 80%;
	height: auto;
	margin: auto;
	display: block;
	color: #32707E;
}

.committeeResourcesArea .resourceGridBox .resourceGridItem p {
	margin: 10px auto;
	text-align: center;
	color: #2f557f;
	text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.committeeResourcesArea .resourceGridBox .resourceGridItem.listItem {
	width: 100%;
	margin: 0;
}

.committeeResourcesArea .resourceGridBox .resourceGridItem.listItem img, .committeeResourcesArea .MuiSvgIcon-root {
	width: 35px;
	height: 35px;
}

.committeeResourcesArea .resourceGridBox .resourceGridItem.listItem .resourcesRows {
	height: auto;
}

.committeeResourcesArea .resourceGridBox .resourceGridItem.listItem .resourcesCol2 p {
	margin: auto 10px;
	width: 100%;
	text-align: left;
}

.committeeResourcesArea .resourceGridBox .resourceGridItem.listItem .resourcesCol1 {
	text-align: left;
}

.committeeResourcesArea .resourcesTable {
	margin-right: 20px;
	margin-bottom: 20px;
}

.committeeResourcesArea .resourcesRows.evenRow {
	background-color: rgba(65, 133, 147, 0.2);
}

.committeeResourcesArea .resourcesRows .resourcesCol1 {
	width: 20%;	
}

.committeeResourcesArea .resourcesRows .resourcesCol2 {
	width: 60%;	
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	align-content: flex-start;
}

.committeeResourcesArea .resourcesRows .resourcesCol2 .icons {
	position: relative;
	top: 4px;
	margin-right: 5px;
}

.committeeResourcesArea .resourcesRows .resourcesCol3 {
	width: 15%;	
}

.committeesNewsContainer .newsListing {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.committeesNewsContainer .newsListing .innerWrapper {
	width: 70%;
}

.committeesNewsContainer .searchBox {
    width: 25%;
	display: flex;
	flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
}

.committeeIntroArea .committeeIntroFrame {
	width: 100%;
	display: block;
	position: relative;
	overflow-x: scroll;
	padding-bottom: 20px;
}

.committeeResourcesArea .resourcesHeading .resourcesCol4 {
	display: none;
}

.committeeResourcesArea .resourcesHeading .resourcesCol1, .committeeResourcesArea .resourcesHeading .resourcesCol2 {
	display: block;
}

.committeeResourcesArea .resourcesHeading .resourcesCol1, .committeeResourcesArea .resourcesHeading .resourcesCol2, .committeeResourcesArea .resourcesHeading .resourcesCol3, .committeeResourcesArea .resourcesHeading .resourcesCol4 {
	margin: 0 10px;
}

.committeeResourcesArea .resourcesHeading .resourcesCol3 {
	text-align: center;
}

.committeeResourcesArea .mobileRow {
	display: none;
}

.committeeResourcesArea .desktopRow {
	display: block;
}

.committeesContainer .bodyContent .subCatBanner ul {
	display: flex;
}


@media only screen and (max-width: 1300px) { 
	
	/*.committeesContainer .topBanner .wrapper,
	.committeesContainer .bottomBanner .wrapper {
		width: calc(100% - (100vw/7) + 0px);
		margin: 0;
	}*/

	.committeesContainer .banner .navigationBar {
		left: 30px;
	}

	/*.committeesContainer .topBanner .innerWrapper,
	.committeesContainer .bottomBanner .innerWrapper {
		padding-left: 20px;
	}*/

	.committeesContainer .banner .wrapper h1 {
		margin-left: 20px;
	}

	.committeesContainer .bodyContent .wrapper {
		background-color: transparent;
		width: calc((100vw/7) * 6 - 40px);
		margin: 0;
		padding-left: 10px;
	}

	.committeesContainer .committeeIntroFrame img {
		display: block;
		max-width: 90%;
		margin: auto;
	}

	.committeeIntroArea .committeeIntroFrame p {
		word-break: break-all;
	}

	.committeeIntroArea.active, .committeeNewsArea.active, .committeeResourcesArea.active {
		padding: 0 20px;
	}

}

@media only screen and (max-width: 800px) { 

	.committeesContainer .bodyContent .wrapper {
		width: calc((100vw/7) * 6 - 10px);
	}

	.bottomBanner .topMenu,
	.topBanner .languageMenu {
		display: none;
	}

	.committeesContainer .subCatMenu {
		display: block;
		left: 0;
		padding-left: 0px;
	}


	.committeesContainer .wrapper .subCatMenu li {
		/*width: 64px;*/
	}


	.committeeIntroArea .committeeIntroFrame, .committeeNewsArea {
		width: calc((100vw/7) * 6 - 20px);
		margin: 0;
		margin-right: 10px;
	}


	.committeeIntroArea .committeeIntroFrame blockquote {
		margin-left: 0 !important;
	}

	.committeeNewsArea .newsListing .newsGroup div.thumbnailArea {
		width: 50%;
	}

	.committeeNewsArea .newsListing .newsGroup div.descArea {
		width: 50%;
	}

	.committeeNewsArea .newsListing .newsGroup h5, .committeeNewsArea .newsListing .newsGroup h4 {
		padding: 5px 10px;
	}

	.committeeNewsArea .newsListing .newsGroup img {
		width: 100%;
	}

	.committeesNewsContainer .newsListing {
		flex-direction: column-reverse;
	}

	.committeesNewsContainer .newsListing .innerWrapper {
		width: 100%;
		padding-bottom: 20px;
		overflow-x: scroll;
	}

	.committeesNewsContainer .searchBox {
		width: 100%;
	}

	.committeeResourcesArea .resourcesTable {
		min-height: 350px;
		margin-right: 10px;
	}

	.committeeResourcesArea .resourcesRows .resourcesCol3 {
		width: 25%;
	}

	.committeeResourcesArea .mobileRow .resourcesRows {
		height: 70px;
	}

	.committeeResourcesArea .resourceGridBox .resourceGridItem {
		width: 25%;
		display: block;
		margin: 20px;
	}

	.committeeResourcesArea .mobileRow .resourcesRows .resourceCol2Content {
		width: 80%;
	}

	.committeeResourcesArea .resourceGridBox .resourceGridItem p {
	    overflow-wrap: break-word;
	    white-space: normal;
	}

	.committeeResourcesArea .mobileRow .resourcesRows p.resourcesCol1 {
		line-height: 18px;
		/*color: rgba(47,85,127,0.9);*/
		/*color: #5d6875;*/
	}

	.committeeResourcesArea .mobileRow .resourcesRows .resourcesCol2 p {
		line-height: 35px;
	}

	.committeeResourcesArea .mobileRow .resourcesRows .resourcesCol2 p:last-of-type {
		line-height: 12px;
	}

	.committeeResourcesArea  .listItem  .mobileRow  .resourcesRows .resourcesCol2 p {
		line-height: 20px;
	}

	.committeeResourcesArea  .listItem  .mobileRow  .resourcesRows .resourcesCol3 {
		line-height: 35px;
		margin-top: 0px;
		margin-bottom: 20px;
	}

	.committeeResourcesArea .listItem  .mobileRow .resourcesRows .resourcesCol2 p:last-of-type {
		line-height: 12px;
		font-size: 12px;
	}

	.committeeResourcesArea .mobileRow .resourcesCol12 {
		width: 80%;
	}

	.committeeResourcesArea .mobileRow .resourcesCol2 {
		width: 100%;
	}

	.committeeResourcesArea .mobileRow {
		display: block;
	}

	.committeeResourcesArea .desktopRow {
		display: none;
	}

	.committeeResourcesArea .resourcesHeading .resourcesCol4 {
		display: block;
		width: 80%;
	}

	.committeeResourcesArea .resourcesHeading .resourcesCol1, .committeeResourcesArea .resourcesHeading .resourcesCol2 {
		display: none;
	}

	.committeeResourcesArea .resourcesRows .resourcesCol2 .icons {
		width: 1.2em;
		height: 1.2em;
		/*top: 15px;*/
		margin-right: 8px;
	}

}


@media only screen and (max-width: 600px) { 

	.committeeIntroArea.active, .committeeNewsArea.active, .committeeResourcesArea.active {
		padding: 0 5px;
	    margin-top: 10px;
	    margin-right: 10px;
	}


	.committeeResourcesArea .resourceGridBox .resourceGridItem {
		width: calc(50% - 20px);
		display: block;
		margin: 10px;
	}

	.committeeResourcesArea .resourceGridBox .resourceGridItem img {
		width: 60%;
	}

}



