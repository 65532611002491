* {
	font-family: Microsoft JhengHei;
}

@font-face {
    font-family: AdventSans;
    src: url("../../fonts/AdventSans-Logo.otf") format("opentype");
}

.landingContainer .banner .wrapper {
	background-color: transparent;
	width: calc((100vw/7) * 5);
	margin: auto;
}

.landingContainer .homeBanner {
	/*background-size: cover;*/
	/*background-image: url('../../images/homeBanner.png');*/
	height: 520px;
	width: calc(100% - (100vw/7));
	/*background-position: top;*/
	position: relative;
}

.landingContainer .banner h2 {
	color: white;
	font-weight: bold;
	font-variant: small-caps;
	font-family: AdventSans;
	font-size: 40px;
	margin: 0;
	padding: 0;
}
.landingContainer .banner div.bannerContent {
	position: absolute;
	bottom: 20px;
	left: 30px;
}

.landingContainer .banner div.donate {
	background-color: white;
	width: 170px;
	cursor: pointer;
}

.landingContainer .banner div.donate p {
	font-family: AdventSans;
	padding: 10px;
	color: #2f557f;
	font-variant: small-caps;
	font-weight: bold;
	font-size: 25px;
	text-align: center;
	position:relative;
	/*top: -3px;*/
	margin: 0;
}

.bodyContent {
	position: relative;
    min-height: calc(100vh - 191px);
}

.filterTagDiv {
	margin-top: 20px;
	margin-bottom: 20px;
	font-size: 12px;
}

.filterTagDiv .filterTag {
    border: solid 1px gray;
    background-color: #ffffff;
    padding: 2px 8px;
    border-radius: 8px;
    color: gray;
    margin-top: 4px;
    margin-right: 10px;
    cursor: pointer;
}

.content {/*
	background-color: red;*/
	/*height: 600px;*/
	width: calc((100vw/7) * 5);
	margin: auto;
}

.content .section {
	padding-top: 20px;
}

.content .sectionHeader {
	display: flex;
}

.content .indicator {
	background-color: #2f557f;
	height: 30px;
	width: 8px;
	margin-right: 10px;
	margin-top: 7px;
}

.content h3 {
	margin: 0;
	font-size: 30px;
	color: #2f557f;
	letter-spacing: 5px;
}

.content .videoListing img {
   width: 85%;
}

.content .videoListing p {
	font-size: 20px;
	font-weight: bold;
	margin: 0;
}

.content .newsListing,
.content .videoListing {
	display: flex;
	flex-wrap: wrap;
	margin-top: 10px;
}

.content .videoListing .videoGroup {
	width: 45%;
	margin-bottom: 20px;
}

.content .newsListing .newsGroup {
	display: flex;
	width: 45%;
	margin-bottom: 30px;
}

.content .newsListing .newsGroup > div {
	min-width: 50%;
}

.tagDiv {
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 10px; 
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	font-size: 12px;
}

.tagSpan {
	border: solid 1px gray;
	background-color: white;
	padding: 2px 8px;
	border-radius: 8px;
	color: gray;
	margin-top: 4px;
	margin-left: 10px;
	cursor: pointer;
	/*margin-bottom: 2px;*/
	/*background-color: red;*/
	display: block;

}

.content .newsListing .newsGroup img {
	width: 100%;
    height: 100%;
    max-height: 200px;
    object-fit: cover;
    object-position: top left;
    cursor: pointer;
}

.content .newsListing .newsGroup p {
	margin: 0;
	color: #666262;
	font-family: AdventSans;
	font-size: 12px;
}

.content .newsListing .newsGroup h4 {
	font-weight: bold;
	font-size: 18px;
	cursor: pointer;
}

.content .newsListing .newsGroup h5 {
	font-weight: lighter;
	font-size: 14px;
}

.content .newsListing .newsGroup h4 {
	margin: 0;
	padding: 0 20px;
}

.content .newsListing .newsGroup h5 {
	margin: 0;
	padding: 5px 20px;
}

.content .newsListing .newsGroup .readMore {
	font-size: 16px;
	text-align: center;
	border: solid 1px #2f557f;
	color: #2f557f;
	font-weight: bold;
	border-radius: 5px;
	width: 100px;
	margin-left: 20px;
	margin-top: 10px;
	cursor: pointer;
}

.landingContainer .sideBar {
	width: calc(100vw/7 * 1 - 0.01px);
	background-color: #2f557f;
	height: calc(100% + 3px);
	position: absolute;
	top: -3px;
	right: 0;
	z-index: 0;
}

.videoFrameContent {
	width: 85%;
	overflow-x: scroll;
}

.videoFrameContent video {
	width: 100% !important;

}
.videoFrameContent iframe {
  width: 100%;
  height: 100%;
}

.sideBar .logo {
	width: 60%;
	max-width: calc(100vw/7 * 0.6);
	margin-left: calc(100vw/7 * 0.2 );
	margin-right: calc(100vw/7 * 0.2 );
	margin-top: calc(100vw/7 * 0.1 );
	position: fixed;
	top: 0;
}

.banner-anim{
  height: 100%;
}

.banner-anim:hover{
  cursor: pointer;
}

.banner-anim-elem .bg{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media only screen and (max-width: 1300px) { 
	.landingContainer .banner .wrapper {
		background-color: transparent;
		width: calc((100vw/7) * 6 - 0.01px);
		margin: 0;
		padding-left: 10px;
	}

	.topBanner .wrapper, .bottomBanner .wrapper {
		padding-left: 20px;
	}

	.content {/*
		background-color: red;*/
		/*height: 600px;*/
		width: calc((100vw/7) * 6 - 40px);
		margin: 0;
		padding-left: 30px;
	}

}

@media only screen and (max-width: 800px) { 


	.landingContainer .topBanner .wrapper, .landingContainer .bottomBanner .wrapper {
		width: calc(100% - 30px);
	}

	.landingContainer {
		overflow-x: hidden;
		/*width: 90%;*/
	}
	.landingContainer .banner h2 {
		font-size: 6vw;
	}

	.landingContainer .banner div.donate p {
		font-size: 4vw;
	}

	.content .newsListing .newsGroup {
		display: flex;
		width: 100%;
		margin-bottom: 30px;
	}

	.content .videoListing .videoGroup {
		width: 100%;
		margin-bottom: 20px;
	}


	.landingContainer .banner div.donate {
		background-color: white;
		width: 35vw;
	}


	.sideBar { 
		/*display: none;*/
	}


	.videoFrameContent {
		width: 95%;
	}

	.content .newsListing .newsGroup .readMore {
		margin-left: 10px;
	}

	.tagDiv {
		margin: 0;
	}

}

@media only screen and (max-width: 600px) { 
	.content .newsListing .newsGroup {
		display: block;
		
		margin-bottom: 30px;
	}

	.content .newsListing .newsGroup img {
		width: 100%;
		max-width: 90%;
	}

	.content .newsListing .newsGroup img,
	.content .newsListing .newsGroup img+p,
	.content .newsListing .newsGroup h4,
	.content .newsListing .newsGroup h5,
	.content .videoListing .videoGroup {
		padding-left: 10px;
	}

	.content .videoListing .videoGroup video {
		max-width: 280px;
	}

	.content .newsListing .newsGroup img+p {
		margin-bottom: 5px;
	}
}
