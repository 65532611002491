.newsContainer .bodyContent .wrapper {
	width: calc((100vw/7) * 5);
	margin: auto;
	position: relative;
	height: 100%;
}

.newsContainer .bodyContent .innerWrapper {
	padding: 0 20px;
}

.newsContainer  .smallText {
	font-size: 13px;
}

.newsContainer .searchIconDiv {
	color: white;
	/*border: solid 1px red;*/
	width: 100%;
	border-radius: 10px;
	margin-top: 10px;
	background-color: #2f557f;
}

.newsContainer .searchIconDiv .searchIcon {
	position: relative;
    top: 6px;
    /*left: 6px;*/
}

.newsContainer .searchIconDiv span {
	line-height: 35px;
	margin-left: 5px;
	letter-spacing: 3px;
	font-weight: bold;
}

.newsContainer .searchIconInnerDiv {
	/*border: solid 1px red;*/
	width: 100px;
	margin: auto;
}

.newsContainer .searchBox {
	width: 25%;
	display: flex;
	flex-direction: row;
    align-items: flex-start;
    align-content: flex-start;
}

.newsContainer.newsListingContainer .searchBox {
	display: block;
}

.newsArea {
	display: flex;
	justify-content: flex-start;
	margin-top: 20px;
}

.newsContainer .filterTextField {
	height: 35px;
	border: solid 1px #2f557f;
	width: 100%;
	font-size: 16px;
	color: #2f557f;
	text-indent: 10px;
	padding: 0;
}

.newsContainer .newsListing {
	width: 70%;
}

.newsContainer .newsListing .newsGroup {
	display: flex;
	flex-direction: row;
	/*width: 60%;*/
	margin-bottom: 30px;
	

	/*border: solid 1px red;*/
}

.newsContainer .newsListing .newsGroup div.thumbnailArea { 
	width: 100%;
	min-width: 50%;
	overflow: hidden;
}

.newsContainer .newsListing .newsGroup img {
	width: 100%;
	display: block;
    height: 100%;
    max-height: 275px;
    object-fit: cover;
    object-position: top left;
    cursor: pointer;
}

.newsContainer .newsListing .newsGroup div.descArea {
	min-width: 50%;
	/*border: solid 1px green;*/
}

.newsContainer .newsListing .newsGroup p {
	margin: 0;
	color: #666262;
	font-family: AdventSans;
	font-size: 12px;
}

.newsContainer .newsListing .newsGroup h4 {
	font-weight: bold;
	font-size: 18px;
    cursor: pointer;
}

.newsContainer .newsListing .newsGroup h5 {
	font-weight: lighter;
	font-size: 14px;
}

.newsContainer .newsListing .newsGroup h4 {
	margin: 0;
	padding: 0 20px;
}

.newsContainer .newsListing .newsGroup h5 {
	margin: 0;
	padding: 5px 20px;
}

.newsContainer .newsListing .newsGroup .readMore {
	font-size: 16px;
	text-align: center;
	border: solid 2px #2f557f;
	color: #2f557f;
	font-weight: bold;
	border-radius: 5px;
	width: 100px;
	margin-left: 20px;
	margin-top: 5px;
}

.Demo__some-network__share-button {
	margin-right: 10px;
	margin-bottom: 10px;
}

.wechatIcon {
	position: relative;
    cursor: pointer;
}

.wechatIcon > img {
	width: 32px;
	height: 32px;
}

.wechatIcon .wechatQrcode {
	position: absolute;
	margin-top: 10px;
	display: none;
	background-color: #fff;
	padding: 10px;
}

.wechatIcon:hover .wechatQrcode {
	display: block;
}

/*.newsListingContainer .bodyContent .subCatBanner ul,
.newsContainer .bodyContent .subCatBanner ul {
	display: flex;
}*/

@media only screen and (max-width: 1300px) { 
	
	/*.newsContainer .topBanner .wrapper,
	.newsContainer .bottomBanner .wrapper {
		width: calc(100% - (100vw/7) + 20px);
		margin: 0;
	}*/

	.newsContainer .banner .wrapper h1 {
		margin-left: 20px;
	}

	.newsContainer .bodyContent .wrapper {
		background-color: transparent;
		width: calc((100vw/7) * 6 - 10.01px);
		margin: 0;
		padding-left: 10px;
	}


}

@media only screen and (max-width: 800px) { 
	.bottomBanner .topMenu,
	.topBanner .languageMenu {
		display: none;
	}

	.newsContainer .newsArea {
		flex-flow: column-reverse;
		flex-wrap: wrap;
	}

	.newsContainer .newsListing .newsGroup {
		flex-direction: column;
	}

	.newsContainer .newsListing .newsGroup img {
		display: block;
		width: 100%;
	}

	.newsContainer .newsListing .newsGroup div.thumbnailArea {
		width: 100%;
	}

	.newsContainer .newsListing .newsGroup div.descArea {
		width: 100%;
	}

	.newsContainer .newsListing,
	.newsContainer .searchBox {
		width: calc(100% - (100%/7));
		margin-left: 20px;
	}

	.tagDiv {

	}

	.newsContainer .searchBox {
		margin-bottom: 20px;
	}

	.newsContainer .footer .wrapper {
		width: calc((100vw/7) * 6);
		margin: 0;
		padding-left: 10px;
		margin-left: 20px;
	}

	.newsContainer .newsListing .newsGroup h5, .newsContainer .newsListing .newsGroup h4 {
		padding: 5px 0px;
	}
	.newsContainer .newsListing .newsGroup .readMore {
		margin-left: 0px;
	}
	.newsContainer .newsListing .newsGroup .tagSpan {
		margin-left: 0;
		margin-right: 10px;
	}


	.newsContainer .newsArea .innerWrapper {
		padding: 0;
		position: relative;
	    width: 100%;
	    display: block;
	    overflow: scroll;
	}


	.mobileMenu {
		display: block;
	}
}

