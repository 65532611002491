* {
	font-family: Microsoft JhengHei;
}

.contactContainer h3 {
	padding: 0;
	margin: 0;
	color: #2f557f;
	font-weight: bold;
}

.contactContainer p {
	margin-top: 10px;
	margin-bottom: 10px;
	white-space: pre-line;
}

.contactContainer p.beliefsContent {
	/*margin-left: 22px;*/
	font-size: 16px;
}

.contactContainer ul {
	list-style: none;
	padding-left: 20px;
}

.contactContainer ul li {
	margin-bottom: 10px;
}

.contactContainer .organization .sectionHeader {
	margin-top: 20px;
	margin-bottom: 10px;
}

.contactContainer .organization h3,
.contactContainer .organization p {
	padding-right: 30px;
}

.MuiFormControl-root.mobileDropdown {
	position: absolute;
	right: 50px;
}

.MuiSvgIcon-root.MuiSelect-icon {
	color: white;
}

.banner {
	background-size: cover;
	background-image: url('../../images/banner.png');
	height: 15rem;
    width: calc((100vw/7) * 6);
	background-position: center;
	position: relative;
	background-repeat: no-repeat;
}

.contactContainer .bodyContent .wrapper {
	width: calc((100vw/7) * 5);
	margin: auto;
	position: relative;
	height: 100%;
	background-color: transparent;
}

.contactContainer .bodyContent .innerWrapper {
	padding: 0 20px;
	background-color: transparent;
}


.contactContainer .innerWrapper span, .contactContainer .innerWrapper table {
	background-color: transparent !important;
}

.contactContainer .innerWrapper table tr td span {
	font-size: 22px !important;
	color: #2f557f !important;
}

.contactContainer  .smallText {
	font-size: 13px;
}

.subCatBanner {
	background-size: cover;
	background-image: url('../../images/subCatBackground.png');
	height: 50px;
	background-position: center;
	position: relative;
    width: calc((100vw/7) * 6);
}

.contactContainer .bodyContent .subCatBanner .wrapper {
	width: calc(100% - 40px);
	margin-left: 20px;
	margin-right: 20px;
	padding: 0;
}

.bodyContent .subCatBanner ul {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	align-content: center;
	/*width: 100%;*/
    overflow: scroll;
    white-space: nowrap;
    overflow-wrap: break-word;
}

::-webkit-scrollbar { 
    background: transparent;
    width: 0px;
}

.subCatMenu li {
	text-align: right;
}

.subCatMenu li:hover {
	cursor: pointer;
}

.bodyContent .subContent {
	display: none;
}

.bodyContent .activeSubContent {
	display: block;
}

.banner h1 {
	color: white;
	position: absolute;
	bottom: 15px;
	margin: 0;
}

.subCatMenu {
	list-style: none;
	position: absolute;
	right: 0;
}

.subCatMenu li {
	display: block;
	margin: 0 10px;
	font-weight: bold;
	color: white;
}

.bodyContent .sectionHeader {
	display: flex;
}

.bodyContent .sectionHeader h3 {
	margin: 0;
	font-size: 22px;
	color: #2f557f;
	letter-spacing: 5px;
}

.bodyContent .indicator {
	background-color: #2f557f;
	height: 22px;
	width: 6px;
	margin-right: 7px;
	margin-top: 5px;
}

.sideBar {
	width: calc(100vw/7 * 1 - 0.01px);
	background-color: #2f557f;
	height: 100%;
	position: fixed;
	top: 0;
	right: 0;
}

.mobileDropdown.MuiFormControl-root {
	display: none;
}

@media only screen and (max-width: 1300px) { 
	
	/*.contactContainer .topBanner .wrapper,
	.contactContainer .bottomBanner .wrapper {
		width: calc(100% - (100vw/7) + 0px);
		padding-left: 30px;
		margin: 0;
	}*/


	.contactContainer .bodyContent .wrapper {
		background-color: transparent;
		width: calc((100vw/7*6) - 60px);
		margin: 0;
		padding-left: 40px;
		/*padding-right: 20px;*/
	}

	.contactContainer .bodyContent .innerWrapper {
		padding: 0;
		padding-right: 20px;
		margin-right: 60px;
	}

	.contactContainer .footer .wrapper {
		width: calc((100vw/7) * 6);
		margin-left: 20px;
		padding-left: 10px;
	}

	.contactContainer .bodyContent .contactUsArea {
		width: calc(100% - 14.2857vw - 0px);
	}

}


@media only screen and (max-width: 1000px) { 

	.bodyContent .subCatBanner ul {
		display: none;
	}

	.mobileDropdown.MuiFormControl-root {
		display: block;
		position: relative;
    	top: 5px;
	}

}

@media only screen and (max-width: 800px) { 
	

	.contactContainer .bodyContent .wrapper {
		background-color: transparent;
		width: calc((100vw/7*6) - 60px);
		margin: 0;
		padding-left: 20px;
		padding-right: 10px;
	}

	/*.contactContainer .bodyContent .subCatBanner .wrapper {
		width: calc((100vw/7*6) - 20px);
	}*/

	.bodyContent .subCatBanner ul {
		justify-content: flex-start;
	}

	.contactContainer .footer .wrapper {
		width: calc((100vw/7) * 6);
		margin-left: 20px;
		padding-left: 10px;
	}


	.contactContainer .footer .wrapper video {
		max-width: 300px;
	}

	.banner {
		background-size: cover;
		background-image: url('../../images/banner.png');
		/*height: 175px;*/
	    width: calc((100vw/7) * 6);
		background-position: center;
		position: relative;
	}

	.contactContainer .bodyContent .innerWrapper {
		margin-right: 20px;
	}
}
