
* {
	font-family: Microsoft JhengHei;
}
*:lang(en) {
	font-family: AdventSans;
}


@font-face {
    font-family: AdventSans;
    src: url("../../fonts/AdventSans-Logo.otf") format("opentype");
	unicode-range: U+00-FF;
}
@font-face {
    font-family: Microsoft JhengHei;
    src: url("../../fonts/AdventSans-Logo.otf") format("opentype");
}

a{
	text-decoration: none;
}

body {
	background-color: transparent;
	font-family: AdventSans, "Microsoft JhengHei";
}

.landingContainer {
	position: relative;
}

.leftMenu .MuiTypography-body1 {
	text-align: left;
	padding-left: 20px;
}

.MuiDrawer-paper {
	width: 250px;
}

.mobileMenu {
	display: none;
	color: #2f557f;
}

.mobileMenu:hover {
	cursor: pointer;
}

.mobileSubMenu {
	padding-left: 20px;
	color: #4a4a4a;
	max-height: 0;
	transition: max-height 1s; 
	overflow: hidden;
}

.mobileSubMenu.open {
	max-height: 300px;
	overflow-y: scroll;
}

.leftMenu div.mobileSubMenu .MuiTypography-root {
	font-size: 15px;
}

.mobileSubMenu .MuiListItem-gutters {
	padding-left: 5px;

}

.header .topBanner {

}

.topBanner .logo {
	width: 80px;
	position: absolute;
	top: 10px;
	right: 4%;
}

.logoChi {
	margin: 0;
	color: #2f557f;
	padding: 0;
}

.topBanner .wrapper,
.bottomBanner .wrapper {
	width: calc((100vw/7) * 6 - 60px);
	margin: auto;
	margin-left: 30px;
	/*border: solid 1px black;*/
	display: flex;
}

.topBanner {
	height: 30px;
	background-color: #ffffff;
	padding-right: 30px;
}

.bottomBanner {
	padding-right: 30px;
}

.topBanner .wrapper {
	display: flex;
	justify-content: space-between;
}

.topBanner a {
	text-decoration: none;
	color: #2f557f;
}

.topBanner .languageMenu {
	font-size: 14px;
	position: relative;
	top: 4px;
}

.topBanner .languageMenu label {
	margin: 0 10px;
	color: white;
}

.topBanner a:hover {
	cursor: pointer;
}

.topBanner h1 {
	color: #2f557f;
	margin: 0;
	font-variant: small-caps;
	font-size: 20px;
	/*border: solid 1px green;*/
	/*width: 500px;*/
	font-family: AdventSans;
}

.bottomBanner .wrapper {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
	margin-bottom: 10px;
}

.bottomBanner h1 {
	font-size: 20px;
	white-space: pre;
}

.bottomBanner .topMenu {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	list-style: none;
	position: relative;
    max-width: 75%;
}

.topMenu > li {
	margin: 0;
	margin-left: 20px;
}

.bottomBanner .topMenu a{
	color: #2f557f;
	font-weight: bold;
	font-size: 16px;
	display: inline-block;
	/*border: solid 1px black;*/
	text-align: right
}

.bottomBanner .subMenu {
	list-style: none;
	transition: max-height 1s; 
    max-height: 0;
    opacity: 0;
	border-left: solid 1px rgba(47,85,127,1);
	margin: 0;
	padding: 0;
	position: absolute;
	top: 30px;
	z-index: 20;
	overflow: hidden;
	/*background-color: rgba(47,85,127,1);*/
	background-color: #e8eef2;
	/*transition:.2s opacity;*/
	padding: 5px 10px;
	 -webkit-box-shadow: 0 10px 6px -6px #777;
     -moz-box-shadow: 0 10px 6px -6px #777;
          box-shadow: 0 10px 6px -6px #777;
}

.bottomBanner .subMenu .subSubMenu {
	list-style: none;
	background-color: #e8eef2;
	padding-left: 20px;
	max-height: 0;
	transition: max-height 1s; 
	overflow: hidden;
}

.bottomBanner .subMenu .subSubMenu.open {
	max-height: 300px;
}

.bottomBanner .subMenu .subSubMenu li:hover{
	color: rgba(47,85,127,1);
}

.bottomBanner .subMenu.open {
	display: block;
	max-height: 2200px;
	opacity: 1;
}

.bottomBanner .subMenu li {
	/*height: 30px;*/
	color: #4a4a4a;
	
	font-size: 16px;
	width: 160px;
	padding: 5px 0px;

}

.bottomBanner .subMenu li:hover {
	color: white;
	color: rgba(47,85,127,1);
	font-weight: bold;
	cursor: pointer;
}

.bottomBanner .logoSpace {
	width: 100px;
}

.topBanner .innerWrapper,
.bottomBanner .innerWrapper {
	display: flex;
	width: 100%;
	/*border: solid 1px red;*/
	justify-content: space-between;
}

.header .bottomBanner .innerWrapper {
	display: block;
}

.header .bottomBanner .innerWrapper .topMenu {
	max-width: 100%;
	padding: 0;
}


@media only screen and (max-width: 1300px) { 
	.topBanner .wrapper,
	.bottomBanner .wrapper {
		width: calc(100% - calc((100vw/7) * 1 + 20px));
		margin: 0;
		/*padding-left: 10px;*/
		/*border: solid 1px black;*/
		display: flex;
	}

	.bottomBanner .wrapper {
		margin-top: 5px;
		margin-bottom: 5px;
	}

	.bottomBanner .topMenu {
		width: 100%;
	}

	.landingContainer .banner {
		height: 520px;
	}

	.landingContainer .banner div.bannerContent {
		left: 20px;
	}
}

@media only screen and (max-width: 1000px) { 
/*	.bottomBanner .topMenu,
	.topBanner .languageMenu,*/

	.topBanner .wrapper, .bottomBanner .wrapper {
    	width: 100%;
	}

	.header .topBanner .wrapper, .bottomBanner .wrapper {
		width: calc(100% - calc((100vw/7) * 1 + 0px));
	}

	.topBanner .logo 
	{
		display: none;
		
	}

	.mobileMenu {
		display: block;
	}

	.mobileMenu .mobileMenuIcon {
		position: relative;
		top: 3px;
		margin-right: 5px;
	}

	.mobileMenu span {
		position: relative;
		top: -3px;
	}

	.bottomBanner .topMenu,
	.topBanner .languageMenu,
	.subCatMenu, .bodyContent .subCatBanner ul {
		display: none;
	}

}

@media only screen and (max-width: 650px) {


	.bottomBanner .topMenu,
	.topBanner .languageMenu {
		display: none;
	}

	.mobileMenu {
		display: block;
	}


}

@media only screen and (max-width: 500px) {
	.topBanner h1 {
		display: none;
	} 
}
