
.donationContainer p.remarks {
	margin-right: 40px;
}


.donationContainer .bodyContent .wrapper {
	width: calc((100vw/7) * 5);
	margin: auto;
	position: relative;
	height: 100%;
}

.donationContainer .wrapper .container {
	display: flex;
}

.donationContainer .otherDonationMethod {
	border: solid 1px #c0b6b6;
	padding: 20px;
	margin-right: 40px;
	margin-bottom: 20px;
}

.donationContainer .otherDonationMethod p {
	font-family: roboto !important;
}

.donationContainer .hLCrav div.rnc {
	margin: 0;
	padding: 0;
	max-width: 100%;
	flex-direction: inherit;
	flex-flow: row-reverse;
	justify-content: flex-end;
	background-color: transparent;
}

.donationContainer .hLCrav div.rnc canvas {
	background-color: transparent;
	width: 100px;
}

.donationContainer .inputRows {
	margin-top: 10px;
}

.donationContainer .inputRows label {
	margin-bottom: 5px;
}

.donationContainer .radios {
	display: flex;
	flex-wrap: wrap;
}

.donationContainer .radioGroup {
	display: flex;
	margin-right: 40px;
}

.donationContainer .radioGroup.invoiceGroup {
	flex-wrap: wrap;
	margin-bottom: 10px;
}

.donationContainer .radioGroup.invoiceGroup div {
	display: flex;
}

.donationContainer .radioGroup.invoiceGroup > label, .donationContainer .radioGroup.invoiceGroup .textField {
	margin-left: 20px;
}

.donationContainer .radioGroup svg {
	width: 20px;
}


.donationContainer input.textField, .donationContainer .hLCrav input.rnc-input {
	height: 35px;
	background-color: transparent;
	outline: none;
	border: solid 1px #c0b6b6;
	font-size: 20px;
	color: black;
	border-radius: 5px;
	padding: 0;
	width: 60%;
}

.donationContainer input.lastNameTextField.textField {
	width: 15%;
	margin-right: 5%;
}

.donationContainer input.firstNameTextField.textField {
	width: 40%;
}

.donationContainer div.leftContainer, div.rightContainer {
	width: 50%;
	margin-top: 20px;
}

.donationContainer .inputRows {
	margin-bottom: 10px;
}

.donationContainer .paypalBtn {
	width: 60%;
}

.donationContainer .bodyContent label {
	display: block;
	width: 100%;
}

.donationContainer .bodyContent .innerWrapper {
	padding: 0 20px;
	background-color: transparent;
}


@media only screen and (max-width: 1300px) { 


	/*.donationContainer .innerWrapper {
		padding: 0px;
		padding-right: 20px;
	}*/
	
	/*.donationContainer .topBanner .wrapper,
	.donationContainer .bottomBanner .wrapper {
		width: calc(100% - (100vw/7) + 0px);
		padding-left: 30px;
		margin: 0;
	}*/

	.donationContainer div.leftContainer, .donationContainer div.rightContainer {
		width: 48%;
	}

	.donationContainer .bodyContent .wrapper {
		background-color: transparent;
		width: calc(100% - (100vw/7) - 40px);
		margin: 0;
		padding-left: 40px;
		padding-right: 20px;
	}

	.content {/*
		background-color: red;*/
		/*height: 600px;*/
		/*width: calc((100vw/7) * 6);
		margin: 0;
		padding-left: 10px;*/
	}

}

@media only screen and (max-width: 800px) { 
	.bottomBanner .topMenu,
	.topBanner .languageMenu {
		display: none;
	}

	.donationContainer .container {
		flex-wrap: wrap;
	}

	.donationContainer div.leftContainer, .donationContainer div.rightContainer {
		width: 100%;
	}

	.donationContainer input.textField {
		width: 80%;
	}

	.donationContainer input.firstNameTextField.textField {
		width: 60%;
	}

	.donationContainer .footer .wrapper {
		width: calc((100vw/7) * 6);
		margin: 0;
		padding-left: 10px;
	}

	.mobileMenu {
		display: block;
	}
}