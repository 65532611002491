
.inputRows {
	position: relative;
	margin-bottom: 20px;
}

.inputError {
	position: absolute;
	left: 0px;
	bottom: -12px;
	color: red;
	font-size: 10px;
}

.inputError.hide {
	display: none;
}

.hide {
	display: none;
}

@media only screen and (max-width: 800px) { 

	.inputError {
		position: absolute;
		left: 0px;
		bottom: -12px;
		color: red;
		font-size: 10px;
	}

}