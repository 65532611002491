input[type="text"] {
	border: none;
	background-color: transparent;
	border-bottom: solid 1px black;
	width: calc((100vw/7) * 3 - 0.01px);
	outline: 0;
    outline-color: initial;
    outline-style: initial;
    outline-width: 0px;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #717171;
    padding-left: 0;
    line-height: 1.6;
    padding: 0.625rem;
    padding-left: 0;
    font-size: 1.25rem;
    /*margin-bottom: 20px;*/
    padding-top: 30px;
}

.searchContainer .bodyContent .wrapper {
	width: calc((100vw/7) * 5);
	margin: auto;
	position: relative;
	height: 100%;
}

.searchContainer {
	width: calc((100vw/7) * 6);
}

.searchContainer .wrapper {	
	margin: 0;
	padding-left: 10px;
	margin-left: 20px;
}

.searchContainer .subCatBanner .wrapper {
	padding-left: 40px;
}

.searchContainer .subCatBanner {
	height: 100px;
}

.subCatBanner button:hover {
	cursor: pointer;
}


.subCatBanner button {
	background-color: #2f557f;
	border: none;
	padding: 5px 15px;
	border-radius: 5px;
	color: white;
	margin-left: 20px;
}

.subCatBanner button svg {
	height: 10px;
	padding-right: 5px;
	position: relative;
	top: 1px;
}

.resultItem {
	border-left: solid 1px #2f557f;
	padding-left: 20px;
	margin-top: 20px;
}


.resultTitle {
	font-size: 1.25rem;
	font-weight: bold;
	border-bottom: solid 1px #2f557f;
	line-height: 1.6;
	color: #2f557f;
}

.resultDesc {
	line-height: 1.6;
	margin: 10px 0;
}

.resultSource {
	font-size: 12px;
}

.searchContainer .subCatBanner button svg path {
	fill: white;
}