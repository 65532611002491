* {
	font-family: Microsoft JhengHei;
}

@font-face {
    font-family: AdventSans;
    src: url("../../fonts/AdventSans-Logo.otf") format("opentype");
}

.footer .wrapper {
	width: calc((100vw/7) * 5);
	margin: auto;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: flex-end;
}

.footer .bottomMenu {
	display: flex;
	justify-content: space-between;
	width: 500px;
	margin: 0;
	padding: 0;
	padding-top: 10px;
}

.footer {
	font-weight: bold;
}

.footer a {
	text-decoration: none;
	color: white;
}

.footer p {
	 font-family: AdventSans;
	 margin: 0;
	 padding: 0;
}

.footer p span {
	font-family: Microsoft JhengHei;
}

.footer{
	/*height: 70px;*/
	background-color: #2f557f;
	color: white;
}

@media only screen and (max-width: 1300px) { 
	.footer .wrapper {
		width: calc((100vw/7) * 6);
		margin: 0;
		padding-left: 10px;
	}

	
}

@media only screen and (max-width: 800px) {
	.footer p {
		width: 100%;
		font-size: 2vw;
	}
	.footer .bottomMenu {
		display: flex;
		font-size: 2vw;
		width: 80%;
	}

}

#___gcse_0 {
	/*position: absolute;*/
	/*top: 15px;*/
	width: 300px;
	right: 10px;
}
